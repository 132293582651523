import { parseJson } from './utils';

export const traverse = (data: any, path: string) => {
  let keys = path.split('.');
  data = data[keys.shift()];
  while (keys.length > 0 && data) {
    data = data[keys.shift()];
  }
  return data;
};
export const traverseAdvanced = <T>(data: any, path: string): T => {
  let keys = path.split('.');
  data = data[keys.shift()];
  while (keys.length > 0 && data) {
    const nextKey = keys.shift();
    if (nextKey === '*') {
      const type = typeof Object.values(data)[0];
      data = Object.values(data).reduce(
        (l: any, r: any) => (l += r),
        type === 'string' ? '' : type === 'number' ? 0 : null
      );
    } else {
      data = data[nextKey];
    }
  }
  return data;
};

export function coerceObjectProperty<T = any>(value: any): T {
  if (!value) return null;
  try {
    if (typeof value === 'object') return value as T;
    const obj = parseJson(value);
  } catch {
    return null;
  }
}
